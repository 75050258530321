export const COMBO_SITUACION_LABORAL = {
	2: 'Employee',
	1: 'Self-employed',
	3: 'Pensioner',
	4: 'Family Dedication',
	5: 'Unemployed',
	6: 'Student',
	7: 'Rented',
};

export const COMBO_INGRESOS = {
	0: 'No income',
	1: 'Less than or equal to 15,000',
	2: 'Greater than 15,000 up to 35,000',
	3: 'Greater than 35,000 up to 75,000',
	4: 'Greater than 75000',
};

export const COMBO_ORIGEN_INGRESOS = {
	1: 'Payroll/pension/unemployment',
	2: 'Professional activity',
	3: 'Financial Investments',
	4: 'Rentals',
	5: 'Others',
};

export const COMBO_CNO = {
	11: 'Armed Forces Officers',
	12: 'Non-commissioned officers of the armed forces',
	20: 'Troops and seamen of the Armed Forces',
	1111: 'Members of the executive branch (national, regional and local) and of the legislative branch',
	1112: 'Public Administration Management Personnel',
	1113: 'Directors of social interest organizations',
	1120: 'CEOs and executive presidents',
	1211: 'Chief Financial Officers',
	1212: 'Human resources managers',
	1219: 'Directors of policy and planning and of other administrative departments not elsewhere classified',
	1221: 'Sales and commercial managers',
	1222: 'Advertising and public relations managers',
	1223: 'Research and development managers',
	1311: 'Farming and forestry production managers',
	1312: 'Fishery and aquaculture production managers',
	1313: 'Directors of manufacturing industries',
	1314: 'Mining managers',
	1315: 'Directors of supply, transportation, distribution and related companies.',
	1316: 'Construction company directors',
	1321: 'Information and communications technology (ICT) service managers',
	1322: "Directors of children's social services",
	1323: 'Directors-managers of health centers',
	1324: 'Directors of social services for the elderly',
	1325: 'Directors of other social services',
	1326: 'Directors of education services',
	1327: 'Bank, financial services and insurance branch managers',
	1329: 'Managers of other professional service companies not classified under other headings',
	1411: 'Hotel directors and managers',
	1419: 'Directors and managers of other accommodation service companies.',
	1421: 'Restaurant directors and managers',
	1422: 'Directors and managers of bars, cafeterias and similar establishments',
	1429: 'Directors and managers of catering and other food service companies',
	1431: 'Directors and managers of wholesale trade companies',
	1432: 'Directors and managers of retail trade companies',
	1501: 'Directors and managers of recreational, cultural and sports companies',
	1509: 'Directors and managers of waste management companies and other service companies not elsewhere classified.',
	2111: 'Family physicians',
	2112: 'Other medical specialists',
	2121: 'Non-specialized nurses',
	2122: 'Specialized nurses (except midwives)',
	2123: 'Matrons',
	2130: 'Veterinarians',
	2140: 'Pharmacists',
	2151: 'Dentists and stomatologists',
	2152: 'Physiotherapists',
	2153: 'Dietitians and nutritionists',
	2154: 'Speech therapists',
	2155: 'Opticians-optometrists',
	2156: 'Occupational therapists',
	2157: 'Podiatrists',
	2158: 'Occupational and environmental health and hygiene professionals',
	2159: 'Health professionals not classified under other headings',
	2210: 'University and other higher education teachers (except vocational training)',
	2220: 'Vocational training teachers (specific subjects)',
	2230: 'Secondary school teachers (except specific vocational training subjects)',
	2240: 'Primary school teachers',
	2251: 'Early Childhood Education Teachers',
	2252: 'Early Childhood Education Technicians',
	2311: 'Special education teachers',
	2312: 'Special education technicians',
	2321: 'Specialists in didactic and pedagogical methods',
	2322: 'Non-regulated language teachers',
	2323: 'Non-formal music and dance teachers',
	2324: 'Non-regulated arts teachers',
	2325: 'Instructors in information technology in non-formal education',
	2326: 'Environmental education professionals',
	2329: 'Teachers and teaching professionals not classified under other headings',
	2411: 'Physicists and astronomers',
	2412: 'Meteorologists',
	2413: 'Chemicals',
	2414: 'Geologists and geophysicists',
	2415: 'Mathematicians and actuaries',
	2416: 'Statisticians',
	2421: 'Biologists, botanists, zoologists and related fields',
	2422: 'Agricultural engineers',
	2423: 'Forestry engineers',
	2424: 'Agricultural technical engineers',
	2425: 'Forestry and environmental engineers',
	2426: 'Environmental protection professionals',
	2427: 'Winemakers',
	2431: 'Industrial and production engineers',
	2432: 'Construction and civil engineers',
	2433: 'Mechanical Engineers',
	2434: 'Aeronautical engineers',
	2435: 'Chemical engineers',
	2436: 'Mining, metallurgical and related engineers',
	2437: 'Environmental Engineers',
	2439: 'Engineers not classified under other headings',
	2441: 'Electrical Engineers',
	2442: 'Electronics Engineers',
	2443: 'Telecommunications engineers',
	2451: 'Architects (except landscape architects and urban planners)',
	2452: 'Landscape architects',
	2453: 'Urban and traffic engineers',
	2454: 'Engineers, geographers and cartographers',
	2461: 'Industrial and production technical engineers',
	2462: 'Public works technical engineers',
	2463: 'Mechanical Engineers',
	2464: 'Aeronautical technical engineers',
	2465: 'Chemical engineers',
	2466: 'Mining, metallurgical and related technical engineers',
	2469: 'Technical engineers not classified under other headings',
	2471: 'Technical electrical engineers',
	2472: 'Technical engineers in electronics',
	2473: 'Technical engineers in telecommunications',
	2481: 'Technical architects and town planners',
	2482: 'Product and garment designers',
	2483: 'Technical engineers in topography',
	2484: 'Graphic and multimedia designers',
	2511: 'Lawyers',
	2512: 'Prosecutors',
	2513: 'Judges and magistrates',
	2591: 'Notaries and registrars',
	2592: 'Procurators',
	2599: 'Legal professionals not classified under other headings',
	2611: 'Accounting specialists',
	2612: 'Financial and investment advisors',
	2613: 'Financial analysts',
	2621: 'Management and organizational analysts',
	2622: 'Specialists in corporate policy administration',
	2623: 'Public Administration Specialists',
	2624: 'Specialists in personnel and related policies and services',
	2625: 'Specialists in personnel training',
	2630: 'Technicians of tourism companies and activities',
	2640: 'Technical and medical sales professionals (except ICT)',
	2651: 'Advertising and marketing professionals',
	2652: 'Public relations professionals',
	2653: 'Information and communications technology sales professionals',
	2711: 'Systems Analysts',
	2712: 'Software analysts and designers',
	2713: 'Web and multimedia analysts, programmers and designers',
	2719: 'Software and multimedia analysts and designers not classified elsewhere',
	2721: 'Database designers and administrators',
	2722: 'System and network administrators',
	2723: 'Computer network analysts',
	2729: 'Database and computer networking specialists not elsewhere classified',
	2810: 'Economists',
	2821: 'Sociologists, geographers, anthropologists, archaeologists and related professionals',
	2822: 'Philosophers, historians and political science professionals',
	2823: 'Psychologists',
	2824: 'Social work and social education professionals',
	2825: 'Equal opportunity agents for women and men',
	2830: 'Priests of different religions',
	2911: 'Archivists and museum curators',
	2912: 'Librarians, documentalists and related professionals',
	2921: 'Writers',
	2922: 'Journalists',
	2923: 'Philologists, interpreters and translators',
	2931: 'Visual and plastic arts artists',
	2932: 'Composers, musicians and singers',
	2933: 'Choreographers and dancers',
	2934: 'Film, theater and related directors',
	2935: 'Actors',
	2936: 'Radio, television and other broadcasters',
	2937: 'Professionals of bullfighting shows',
	2939: 'Creative and performing artists not classified under other headings',
	3110: 'Draftsmen and technical draftsmen',
	3121: 'Physical and chemical science technicians',
	3122: 'Construction technicians',
	3123: 'Electrical technicians',
	3124: 'Electronics technicians (except electromedical)',
	3125: 'Electronics technicians, specializing in electromedicine',
	3126: 'Mechanical Technicians',
	3127: 'Industrial Chemistry Laboratory Technicians and Analysts',
	3128: 'Metallurgy and mining technicians',
	3129: 'Other technicians in the physical, chemical, environmental and engineering sciences',
	3131: 'Technicians in energy production facilities',
	3132: 'Technicians in waste treatment plants, water treatment plants and other operators in similar plants.',
	3133: 'Technicians in control of chemical processing installations',
	3134: 'Petroleum and natural gas refinery technicians',
	3135: 'Metal production process control technicians',
	3139: 'Process control technicians not classified under other headings',
	3141: 'Life Science Technicians (except in health areas)',
	3142: 'Agricultural technicians',
	3143: 'Forestry and environmental technicians',
	3151: 'Chiefs and machine officers',
	3152: 'Captains and bridge officers',
	3153: 'Aviation pilots and related professionals',
	3154: 'Air traffic controllers',
	3155: 'Aviation safety technicians',
	3160: 'Quality control technicians in the physical, chemical and engineering sciences',
	3201: 'Supervisors in mining engineering',
	3202: 'Construction supervisors',
	3203: 'Food and tobacco industry supervisors',
	3204: 'Chemical and pharmaceutical industry supervisors',
	3205: 'Supervisors of plastics, rubber and natural resins transformation industries.',
	3206: 'Supervisors of wood and pulp and paper industries',
	3207: 'Production supervisors in the graphic arts and paper product manufacturing industries',
	3209: 'Supervisors in other manufacturing industries',
	3311: 'Radiotherapy technicians',
	3312: 'Diagnostic Imaging Technicians',
	3313: 'Technicians in anatomic pathology and cytology',
	3314: 'Clinical diagnostic laboratory technicians',
	3315: 'Orthoprosthetists',
	3316: 'Dental prosthesis technicians',
	3317: 'Hearing aid technicians',
	3321: 'Oral Hygiene Technicians',
	3322: 'Senior technicians in health documentation',
	3323: 'Advanced dietetics technicians',
	3324: 'Optometry technicians',
	3325: 'Physiotherapist assistants',
};
// 	3326: 'Técnicos en prevención de riesgos laborales y salud ambiental',
// 	3327: 'Ayudantes de veterinaria',
// 	3329: 'Técnicos de la sanidad no clasificados bajo otros epígrafes',
// 	3331: 'Profesionales de la acupuntura, la naturopatía, la homeopatía, la medicina tradicional china y la ayurveda',
// 	3339: 'Otros profesionales de las terapias alternativas',
// 	3401: 'Profesionales de apoyo e intermediarios de cambio, bolsa y finanzas',
// 	3402: 'Comerciales de préstamos y créditos',
// 	3403: 'Tenedores de libros',
// 	3404: 'Profesionales de apoyo en servicios estadísticos, matemáticos y afines',
// 	3405: 'Tasadores',
// 	3510: 'Agentes y representantes comerciales',
// 	3521: 'Mediadores y agentes de seguros',
// 	3522: 'Agentes de compras',
// 	3523: 'Consignatarios',
// 	3531: 'Representantes de aduanas',
// 	3532: 'Organizadores de conferencias y eventos',
// 	3533: 'Agentes o intermediarios en la contratación de la mano de obra (excepto representantes de espectáculos)',
// 	3534: 'Agentes y administradores de la propiedad inmobiliaria',
// 	3535: 'Portavoces y agentes de relaciones públicas',
// 	3539: 'Representantes artísticos y deportivos y otros agentes de servicios comerciales no clasificados bajo otros epígrafes',
// 	3611: 'Supervisores de secretaría',
// 	3612: 'Asistentes jurídico-legales',
// 	3613: 'Asistentes de dirección y administrativos',
// 	3614: 'Secretarios de centros médicos o clínicas',
// 	3621: 'Profesionales de apoyo de la Administración Pública de tributos',
// 	3622: 'Profesionales de apoyo de la Administración Pública de servicios sociales',
// 	3623: 'Profesionales de apoyo de la Administración Pública de servicios de expedición de licencias',
// 	3629: 'Otros profesionales de apoyo de la Administración Pública para tareas de inspección y control y tareas similares',
// 	3631: 'Técnicos de la policía nacional, autonómica y local',
// 	3632: 'Suboficiales de la guardia civil',
// 	3711: 'Profesionales de apoyo de servicios jurídicos y servicios similares',
// 	3712: 'Detectives privados',
// 	3713: 'Profesionales de apoyo al trabajo y a la educación social',
// 	3714: 'Promotores de igualdad de oportunidades entre mujeres y hombres',
// 	3715: 'Animadores comunitarios',
// 	3716: 'Auxiliares laicos de las religiones',
// 	3721: 'Atletas y deportistas',
// 	3722: 'Entrenadores y árbitros de actividades deportivas',
// 	3723: 'Instructores de actividades deportivas',
// 	3724: 'Monitores de actividades recreativas y de entretenimiento',
// 	3731: 'Fotógrafos',
// 	3732: 'Diseñadores y decoradores de interior',
// 	3733: 'Técnicos en galerías de arte, museos y bibliotecas',
// 	3734: 'Chefs',
// 	3739: 'Otros técnicos y profesionales de apoyo de actividades culturales y artísticas',
// 	3811: 'Técnicos en operaciones de sistemas informáticos',
// 	3812: 'Técnicos en asistencia al usuario de tecnologías de la información',
// 	3813: 'Técnicos en redes',
// 	3814: 'Técnicos de la Web',
// 	3820: 'Programadores informáticos',
// 	3831: 'Técnicos de grabación audiovisual',
// 	3832: 'Técnicos de radiodifusión',
// 	3833: 'Técnicos de ingeniería de las telecomunicaciones',
// 	4111: 'Empleados de contabilidad',
// 	4112: 'Empleados de control de personal y nóminas',
// 	4113: 'Empleados de oficina de servicios estadísticos, financieros y bancarios',
// 	4121: 'Empleados de control de abastecimientos e inventario',
// 	4122: 'Empleados de oficina de servicios de apoyo a la producción',
// 	4123: 'Empleados de logística y transporte de pasajeros y mercancías',
// 	4210: 'Empleados de bibliotecas y archivos',
// 	4221: 'Empleados de servicios de correos (excepto empleados de mostrador)',
// 	4222: 'Codificadores y correctores de imprenta',
// 	4223: 'Empleados de servicio de personal',
// 	4301: 'Grabadores de datos',
// 	4309: 'Empleados administrativos sin tareas de atención al público no clasificados bajo otros epígrafes',
// 	4411: 'Empleados de información al usuario',
// 	4412: 'Recepcionistas (excepto de hoteles)',
// 	4421: 'Empleados de agencias de viajes',
// 	4422: 'Recepcionistas de hoteles',
// 	4423: 'Telefonistas',
// 	4424: 'Teleoperadores',
// 	4430: 'Agentes de encuestas',
// 	4441: 'Cajeros de bancos y afines',
// 	4442: 'Empleados de venta de apuestas',
// 	4443: 'Empleados de sala de juegos y afines',
// 	4444: 'Empleados de casas de empeño y de préstamos',
// 	4445: 'Cobradores de facturas, deudas y empleados afines',
// 	4446: 'Empleados de mostrador de correos',
// 	4500: 'Empleados administrativos con tareas de atención al público no clasificados bajo otros epígrafes',
// 	5000: 'Camareros y cocineros propietarios',
// 	5110: 'Cocineros asalariados',
// 	5120: 'Camareros asalariados',
// 	5210: 'Jefes de sección de tiendas y almacenes',
// 	5220: 'Vendedores en tiendas y almacenes',
// 	5300: 'Comerciantes propietarios de tiendas',
// 	5411: 'Vendedores en quioscos',
// 	5412: 'Vendedores en mercados ocasionales y mercadillos',
// 	5420: 'Operadores de telemarketing',
// 	5430: 'Expendedores de gasolineras',
// 	5491: 'Vendedores a domicilio',
// 	5492: 'Promotores de venta',
// 	5493: 'Modelos de moda, arte y publicidad',
// 	5499: 'Vendedores no clasificados bajo otros epígrafes',
// 	5500: 'Cajeros y taquilleros (excepto bancos)',
// 	5611: 'Auxiliares de enfermería hospitalaria',
// 	5612: 'Auxiliares de enfermería de atención primaria',
// 	5621: 'Técnicos auxiliares de farmacia',
// 	5622: 'Técnicos de emergencias sanitarias',
// 	5629: 'Trabajadores de los cuidados a las personas en servicios de salud no clasificados bajo otros epígrafes',
// 	5710: 'Trabajadores de los cuidados personales a domicilio',
// 	5721: 'Cuidadores de niños en guarderías y centros educativos',
// 	5722: 'Cuidadores de niños en domicilios',
// 	5811: 'Peluqueros',
// 	5812: 'Especialistas en tratamientos de estética, bienestar y afines',
// 	5821: 'Auxiliares de vuelo y camareros de avión, barco y tren',
// 	5822: 'Revisores y cobradores de transporte terrestre',
// 	5823: 'Acompañantes turísticos',
// 	5824: 'Azafatos de tierra',
// 	5825: 'Guías de turismo',
// 	5831: 'Supervisores de mantenimiento y limpieza en oficinas, hoteles y otros establecimientos',
// 	5832: 'Mayordomos del servicio doméstico',
// 	5833: 'Conserjes de edificios',
// 	5840: 'Trabajadores propietarios de pequeños alojamientos',
// 	5891: 'Asistentes personales o personas de compañía',
// 	5892: 'Empleados de pompas fúnebres y embalsamadores',
// 	5893: 'Cuidadores de animales y adiestradores',
// 	5894: 'Instructores de autoescuela',
// 	5895: 'Astrólogos, adivinadores y afines',
// 	5899: 'Trabajadores de servicios personales no clasificados bajo otros epígrafes',
// 	5910: 'Guardias civiles',
// 	5921: 'Policías nacionales',
// 	5922: 'Policías autonómicos',
// 	5923: 'Policías locales',
// 	5931: 'Bomberos (excepto forestales)',
// 	5932: 'Bomberos forestales',
// 	5941: 'Vigilantes de seguridad y similares habilitados para ir armados',
// 	5942: 'Auxiliares de vigilante de seguridad y similares no habilitados para ir armados',
// 	5991: 'Vigilantes de prisiones',
// 	5992: 'Bañistas-socorristas',
// 	5993: 'Agentes forestales y medioambientales',
// 	5999: 'Trabajadores de los servicios de protección y seguridad no clasificados bajo otros epígrafes',
// 	6110: 'Trabajadores cualificados en actividades agrícolas (excepto en huertas, invernaderos, viveros y jardines)',
// 	6120: 'Trabajadores cualificados en huertas, invernaderos, viveros y jardines',
// 	6201: 'Trabajadores cualificados en actividades ganaderas de vacuno',
// 	6202: 'Trabajadores cualificados en actividades ganaderas de ovino y caprino',
// 	6203: 'Trabajadores cualificados en actividades ganaderas de porcino',
// 	6204: 'Trabajadores cualificados en apicultura y sericicultura',
// 	6205: 'Trabajadores cualificados en la avicultura y la cunicultura',
// 	6209: 'Trabajadores cualificados en actividades ganaderas no clasificados bajo otros epígrafes',
// 	6300: 'Trabajadores cualificados en actividades agropecuarias mixtas',
// 	6410: 'Trabajadores cualificados en actividades forestales y del medio natural',
// 	6421: 'Trabajadores cualificados en la acuicultura',
// 	6422: 'Pescadores de aguas costeras y aguas dulces',
// 	6423: 'Pescadores de altura',
// 	6430: 'Trabajadores cualificados en actividades cinegéticas',
// 	7111: 'Encofradores y operarios de puesta en obra de hormigón',
// 	7112: 'Montadores de prefabricados estructurales (sólo hormigón)',
// 	7121: 'Albañiles',
// 	7122: 'Canteros, tronzadores, labrantes y grabadores de piedras',
// 	7131: 'Carpinteros (excepto ebanistas)',
// 	7132: 'Instaladores de cerramientos metálicos y carpinteros metálicos (excepto montadores de estructuras metálicas)',
// 	7191: 'Mantenedores de edificios',
// 	7192: 'Instaladores de fachadas técnicas',
// 	7193: 'Instaladores de sistemas de impermeabilización en edificios',
// 	7199: 'Otros trabajadores de las obras estructurales de construcción no clasificados bajo otros epígrafes',
// 	7211: 'Escayolistas',
// 	7212: 'Aplicadores de revestimientos de pasta y mortero',
// 	7221: 'Fontaneros',
// 	7222: 'Montadores-instaladores de gas en edificios',
// 	7223: 'Instaladores de conductos en obra pública',
// 	7231: 'Pintores y empapeladores',
// 	7232: 'Pintores en las industrias manufactureras',
// 	7240: 'Soladores, colocadores de parquet y afines',
// 	7250: 'Mecánicos-instaladores de refrigeración y climatización',
// 	7291: 'Montadores de cubiertas',
// 	7292: 'Instaladores de material aislante térmico y de insonorización',
// 	7293: 'Cristaleros',
// 	7294: 'Montadores-instaladores de placas de energía solar',
// 	7295: 'Personal de limpieza de fachadas de edificios y chimeneas',
// 	7311: 'Moldeadores y macheros',
// 	7312: 'Soldadores y oxicortadores',
// 	7313: 'Chapistas y caldereros',
// 	7314: 'Montadores de estructuras metálicas',
// 	7315: 'Montadores de estructuras cableadas y empalmadores de cables',
// 	7321: 'Herreros y forjadores',
// 	7322: 'Trabajadores de la fabricación de herramientas, mecánico-ajustadores, modelistas, matriceros y afines',
// 	7323: 'Ajustadores y operadores de máquinas-herramienta',
// 	7324: 'Pulidores de metales y afiladores de herramientas',
// 	7401: 'Mecánicos y ajustadores de vehículos de motor',
// 	7402: 'Mecánicos y ajustadores de motores de avión',
// 	7403: 'Mecánicos y ajustadores de maquinaria agrícola e industrial',
// 	7404: 'Mecánicos y ajustadores de maquinaria naval y ferroviaria',
// 	7405: 'Reparadores de bicicletas y afines',
// 	7510: 'Electricistas de la construcción y afines',
// 	7521: 'Mecánicos y reparadores de equipos eléctricos',
// 	7522: 'Instaladores y reparadores de líneas eléctricas',
// 	7531: 'Mecánicos y reparadores de equipos electrónicos',
// 	7532: 'Instaladores y reparadores en electromedicina',
// 	7533: 'Instaladores y reparadores en tecnologías de la información y las comunicaciones',
// 	7611: 'Relojeros y mecánicos de instrumentos de precisión',
// 	7612: 'Lutieres y similares; afinadores de instrumentos musicales',
// 	7613: 'Joyeros, orfebres y plateros',
// 	7614: 'Trabajadores de la cerámica, alfareros y afines',
// 	7615: 'Sopladores, modeladores, laminadores, cortadores y pulidores de vidrio',
// 	7616: 'Rotulistas, grabadores de vidrio, pintores decorativos de artículos diversos',
// 	7617: 'Artesanos en madera y materiales similares; cesteros, bruceros y trabajadores afines',
// 	7618: 'Artesanos en tejidos, cueros y materiales similares, preparadores de fibra y tejedores con telares artesanos o de tejidos de punto y afines',
// 	7619: 'Artesanos no clasificados bajo otros epígrafes',
// 	7621: 'Trabajadores de procesos de preimpresión',
// 	7622: 'Trabajadores de procesos de impresión',
// 	7623: 'Trabajadores de procesos de encuadernación',
// 	7701: 'Matarifes y trabajadores de las industrias cárnicas',
// 	7702: 'Trabajadores de las industrias del pescado',
// 	7703: 'Panaderos, pasteleros y confiteros',
// 	7704: 'Trabajadores del tratamiento de la leche y elaboración de productos lácteos (incluidos helados)',
// 	7705: 'Trabajadores conserveros de frutas y hortalizas y trabajadores de la elaboración de bebidas no alcohólicas',
// 	7706: 'Trabajadores de la elaboración de bebidas alcohólicas distintas del vino',
// 	7707: 'Trabajadores de la elaboración del vino',
// 	7708: 'Preparadores y elaboradores del tabaco y sus productos',
// 	7709: 'Catadores y clasificadores de alimentos y bebidas',
// 	7811: 'Trabajadores del tratamiento de la madera',
// 	7812: 'Ajustadores y operadores de máquinas para trabajar la madera',
// 	7820: 'Ebanistas y trabajadores afines',
// 	7831: 'Sastres, modistos, peleteros y sombrereros',
// 	7832: 'Patronistas para productos en textil y piel',
// 	7833: 'Cortadores de tejidos, cuero, piel y otros materiales',
// 	7834: 'Costureros a mano, bordadores y afines',
// 	7835: 'Tapiceros, colchoneros y afines',
// 	7836: 'Curtidores y preparadores de pieles',
// 	7837: 'Zapateros y afines',
// 	7891: 'Buceadores',
// 	7892: 'Pegadores',
// 	7893: 'Clasificadores y probadores de productos (excepto alimentos, bebidas y tabaco)',
// 	7894: 'Fumigadores y otros controladores de plagas y malas hierbas',
// 	7899: 'Oficiales, operarios y artesanos de otros oficios no clasificados bajo otros epígrafes',
// 	8111: 'Mineros y otros operadores en instalaciones mineras',
// 	8112: 'Operadores en instalaciones para la preparación de minerales y rocas',
// 	8113: 'Sondistas y trabajadores afines',
// 	8114: 'Operadores de maquinaria para fabricar productos derivados de minerales no metálicos',
// 	8121: 'Operadores en instalaciones para la obtención y transformación de metales',
// 	8122: 'Operadores de máquinas pulidoras, galvanizadoras y recubridoras de metales',
// 	8131: 'Operadores en plantas industriales químicas',
// 	8132: 'Operadores de máquinas para fabricar productos farmacéuticos, cosméticos y afines',
// 	8133: 'Operadores de laboratorios fotográficos y afines',
// 	8141: 'Operadores de máquinas para fabricar productos de caucho y derivados de resinas naturales',
// 	8142: 'Operadores de máquinas para fabricar productos de material plástico',
// 	8143: 'Operadores de máquinas para fabricar productos de papel y cartón',
// 	8144: 'Operadores de serrerías, de máquinas de fabricación de tableros y de instalaciones afines para el tratamiento de la madera y el corcho',
// 	8145: 'Operadores en instalaciones para la preparación de pasta de papel y fabricación de papel',
// 	8151: 'Operadores de máquinas para preparar fibras, hilar y devanar',
// 	8152: 'Operadores de telares y otras máquinas tejedoras',
// 	8153: 'Operadores de máquinas de coser y bordar',
// 	8154: 'Operadores de máquinas de blanquear, teñir, estampar y acabar textiles',
// 	8155: 'Operadores de máquinas para tratar pieles y cuero',
// 	8156: 'Operadores de máquinas para la fabricación del calzado, marroquinería y guantería de piel',
// 	8159: 'Operadores de máquinas para fabricar productos textiles no clasificados bajo otros epígrafes',
// 	8160: 'Operadores de máquinas para elaborar productos alimenticios, bebidas y tabaco',
// 	8170: 'Operadores de máquinas de lavandería y tintorería',
// 	8191: 'Operadores de hornos e instalaciones de vidriería y cerámica',
// 	8192: 'Operadores de calderas y máquinas de vapor',
// 	8193: 'Operadores de máquinas de embalaje, embotellamiento y etiquetado',
// 	8199: 'Operadores de instalaciones y maquinaria fijas no clasificados bajo otros epígrafes',
// 	8201: 'Ensambladores de maquinaria mecánica',
// 	8202: 'Ensambladores de equipos eléctricos y electrónicos',
// 	8209: 'Montadores y ensambladores no clasificados en otros epígrafes',
// 	8311: 'Maquinistas de locomotoras',
// 	8312: 'Agentes de maniobras ferroviarias',
// 	8321: 'Operadores de maquinaria agrícola móvil',
// 	8322: 'Operadores de maquinaria forestal móvil',
// 	8331: 'Operadores de maquinaria de movimientos de tierras y equipos similares',
// 	8332: 'Operadores de grúas, montacargas y de maquinaria similar de movimiento de materiales',
// 	8333: 'Operadores de carretillas elevadoras',
// 	8340: 'Marineros de puente, marineros de máquinas y afines',
// 	8411: 'Conductores propietarios de automóviles, taxis y furgonetas',
// 	8412: 'Conductores asalariados de automóviles, taxis y furgonetas',
// 	8420: 'Conductores de autobuses y tranvías',
// 	8431: 'Conductores propietarios de camiones',
// 	8432: 'Conductores asalariados de camiones',
// 	8440: 'Conductores de motocicletas y ciclomotores',
// 	9100: 'Empleados domésticos',
// 	9210: 'Personal de limpieza de oficinas, hoteles y otros establecimientos similares',
// 	9221: 'Limpiadores en seco a mano y afines',
// 	9222: 'Limpiadores de vehículos',
// 	9223: 'Limpiadores de ventanas',
// 	9229: 'Otro personal de limpieza',
// 	9310: 'Ayudantes de cocina',
// 	9320: 'Preparadores de comidas rápidas',
// 	9410: 'Vendedores callejeros',
// 	9420: 'Repartidores de publicidad, limpiabotas y otros trabajadores de oficios callejeros',
// 	9431: 'Ordenanzas',
// 	9432: 'Mozos de equipaje y afines',
// 	9433: 'Repartidores, recadistas y mensajeros a pie',
// 	9434: 'Lectores de contadores y recaudadores de máquinas recreativas y expendedoras',
// 	9441: 'Recogedores de residuos',
// 	9442: 'Clasificadores de desechos, operarios de punto limpio y recogedores de chatarra',
// 	9443: 'Barrenderos y afines',
// 	9490: 'Otras ocupaciones elementales',
// 	9511: 'Peones agrícolas (excepto en huertas, invernaderos, viveros y jardines)',
// 	9512: 'Peones agrícolas en huertas, invernaderos, viveros y jardines',
// 	9520: 'Peones ganaderos',
// 	9530: 'Peones agropecuarios',
// 	9541: 'Peones de la pesca',
// 	9542: 'Peones de la acuicultura',
// 	9543: 'Peones forestales y de la caza',
// 	9601: 'Peones de obras públicas',
// 	9602: 'Peones de la construcción de edificios',
// 	9603: 'Peones de la minería, canteras y otras industrias extractivas',
// 	9700: 'Peones de las industrias manufactureras',
// 	9811: 'Peones del transporte de mercancías y descargadores',
// 	9812: 'Conductores de vehículos de tracción animal para el transporte de personas y similares',
// 	9820: 'Reponedores',
// };

export const COMBO_CNAE = {
	'0111': 'Growing of cereals (except rice), leguminous plants and oilseeds',
	'0112': 'Rice cultivation',
	'0113': 'Cultivation of vegetables, roots and tubers',
	'0114': 'Sugarcane cultivation',
	'0115': 'Tobacco cultivation',
	'0116': 'Cultivation of plants for textile fibers',
	'0119': 'Other non-perennial crops',
	'0121': 'Vine cultivation',
	'0122': 'Tropical and subtropical fruit cultivation',
	'0123': 'Citrus cultivation',
	'0124': 'Cultivation of stone and pome fruits',
	'0125': 'Cultivation of other fruit and nut trees and bushes',
	'0126': 'Cultivation of oleaginous fruits',
	'0127': 'Cultivation of plants for beverages',
	'0128': 'Cultivation of spices, aromatic, medicinal and pharmaceutical plants.',
	'0129': 'Other perennial crops',
	'0130': 'Plant propagation',
	'0141': 'Cattle raising for milk production',
	'0142': 'Operation of other cattle and buffaloes',
	'0143': 'Farming of horses and other equines',
	'0144': 'Farming of camels and other camelids',
	'0145': 'Sheep and goat farming',
	'0146': 'Pig farming',
	'0147': 'Poultry',
	'0149': 'Other livestock farms',
	'0150': 'Agricultural production combined with livestock production',
	'0161': 'Agricultural support activities',
	'0162': 'Livestock support activities',
	'0163': 'Post-harvest preparation activities',
	'0164': 'Seed treatment for reproduction',
	'0170': 'Hunting, trapping and related services',
	'0210': 'Forestry and other forestry activities',
	'0220': 'Timber harvesting',
	'0230': 'Harvesting of wild products, except timber',
	'0240': 'Forestry support services',
	'0311': 'Marine fishing',
	'0312': 'Freshwater fishing',
	'0321': 'Marine aquaculture',
	'0322': 'Freshwater aquaculture',
	'3512': 'Electric power transmission',
	'3513': 'Electric power distribution',
	'3514': 'Electric power trading',
	'3515': 'Hydroelectric power production',
	'3516': 'Conventional thermal power generation',
	'3517': 'Nuclear power generation',
	'3518': 'Production of electricity from wind energy',
	'3519': 'Production of other types of electrical energy',
	'3521': 'Gas production',
	'3522': 'Pipeline distribution of gaseous fuels',
	'3523': 'Pipeline gas trade',
	'3530': 'Steam supply and air conditioning',
	'3600': 'Water collection, treatment and distribution',
	'3700': 'Wastewater collection and treatment',
	'3811': 'Collection of non-hazardous waste',
	'3812': 'Hazardous waste collection',
	'3821': 'Treatment and disposal of non-hazardous waste',
	'3822': 'Hazardous waste treatment and disposal',
	'3831': 'Separation and classification of materials',
	'3832': 'Valorization of already sorted materials',
	'3900': 'Decontamination activities and other waste management services',
	'4110': 'Real estate development',
	'4121': 'Construction of residential buildings',
	'4122': 'Construction of non-residential buildings',
	'4211': 'Road and highway construction',
	'4212': 'Surface and subway railroad construction',
	'4213': 'Construction of bridges and tunnels',
	'4221': 'Construction of fluid networks',
	'4222': 'Construction of electrical and telecommunications networks',
	'4291': 'Hydraulic works',
	'4299': 'Construction of other civil engineering projects',
	'4311': 'Demolition',
	'4312': 'Land preparation',
	'4313': 'Drilling and boring',
	'4321': 'Electrical installations',
	'4322': 'Plumbing, heating and air-conditioning systems installations',
	'4329': 'Other construction site installations',
	'4331': 'Revocation',
	'4332': 'Installation of carpentry',
	'4333': 'Floor and wall coverings',
	'4334': 'Painting and glazing',
	'4339': 'Other building finishing',
	'4391': 'Roof construction',
	'4399': 'Other specialized construction activities',
	'4511': 'Sale of automobiles and light motor vehicles',
	'4519': 'Sale of other motor vehicles',
	'4520': 'Maintenance and repair of motor vehicles',
	'4531': 'Wholesale trade of motor vehicle parts and accessories',
	'4532': 'Retail trade of spare parts and accessories for motor vehicles',
	'4540': 'Sale, maintenance and repair of motorcycles and their parts and accessories.',
	'4611':
		'Intermediaries in the trade of agricultural raw materials, live animals, textile raw materials and semi-finished products.',
	'4612': 'Intermediaries in the trade of fuels, minerals, metals and industrial chemical products',
	'4613': 'Intermediaries in the timber and building materials trade',
	'4614': 'Intermediaries in trade of machinery, industrial equipment, ships and aircrafts',
	'4615': 'Intermediaries in the furniture, household goods and hardware trade',
	'4616': 'Intermediaries in the trade of textiles, clothing, fur, footwear and leather goods',
	'4617': 'Intermediaries in food, beverages and tobacco products trade',
	'4618': 'Trade intermediaries specializing in the sale of other specified products',
	'4619': 'Intermediaries in the trade of miscellaneous products',
	'4621': 'Wholesale trade of cereals, raw tobacco, seeds and animal feedstuffs',
	'4622': 'Wholesale trade of flowers and plants',
	'4623': 'Wholesale trade of live animals',
	'4624': 'Wholesale of hides, skins and furs',
	'4631': 'Wholesale trade of fruits and vegetables',
	'4632': 'Wholesale trade of meat and meat products',
	'4633': 'Wholesale of dairy products, eggs, edible oils and fats',
	'4634': 'Wholesale trade of beverages',
	'4635': 'Wholesale trade of tobacco products',
	'4636': 'Sugar, chocolate and confectionery wholesale trade',
	'4637': 'Wholesale trade of coffee, tea, cocoa and spices',
	'4638': 'Wholesale trade of fish and seafood and other food products',
	'4639': 'Non-specialized wholesale trade of food, beverages and tobacco products',
	'4641': 'Wholesale trade of textiles',
	'4642': 'Wholesale trade of clothing and footwear',
	'4643': 'Wholesale trade of electrical household appliances',
	'4644': 'Wholesale trade of porcelain, glassware and cleaning items',
	'4645': 'Wholesale trade of perfumery and cosmetics products',
	'4646': 'Wholesale trade of pharmaceutical products',
	'4647': 'Wholesale trade of furniture, carpets and lighting equipment',
	'4648': 'Wholesale trade of watch and jewelry articles',
	'4649': 'Wholesale trade of other household articles',
	'4651': 'Wholesale of computers, peripheral equipment and computer software',
	'4652': 'Wholesale trade of electronic and telecommunication equipment and their components',
	'4661': 'Wholesale trade of agricultural machinery, equipment and supplies',
	'4662': 'Wholesale trade of machine tools',
	'4663': 'Wholesale trade of mining, construction and civil engineering machinery',
	'4664':
		'Wholesale trade of machinery for the textile industry and of sewing and knitting machines',
	'4665': 'Wholesale of office furniture',
	'4666': 'Wholesale trade of other office machinery and equipment',
	'4669': 'Wholesale trade of other machinery and equipment',
	'4671': 'Wholesale trade of solid, liquid and gaseous fuels, and similar products',
	'4672': 'Wholesale trade of metals and metal ores',
	'4673': 'Wholesale trade of wood, building materials and sanitary ware',
	'4674': 'Wholesale trade of hardware, plumbing and heating products',
	'4675': 'Wholesale trade of chemical products',
	'4676': 'Wholesale trade of other semifinished products',
	'4677': 'Wholesale trade of scrap and waste products',
	'4690': 'Non-specialized wholesale trade',
	'4711':
		'Retail trade in non-specialized establishments, predominantly food, beverages and tobacco products',
	'4719': 'Other retail trade in non-specialized stores',
	'4721': 'Retail sale of fruit and vegetables in specialized stores',
	'4722': 'Retail sale of meat and meat products in specialized stores',
	'4723': 'Retail trade of fish and seafood in specialized establishments',
	'4724':
		'Retail sale of bread and bakery, confectionery and bakery products in specialized stores',
	'4725': 'Retail sale of beverages in specialized stores',
	'4726': 'Retail sale of tobacco products in specialized stores',
	'4729': 'Other retail sale of foodstuffs in specialized stores',
	'4730': 'Retail sale of automotive fuel in specialized stores',
	'4741':
		'Retail sale of computers, peripheral equipment and computer software in specialized stores',
	'4742': 'Retail sale of telecommunications equipment in specialized stores',
	'4743': 'Retail sale of audio and video equipment in specialized stores',
	'4751': 'Retail trade of textiles in specialized stores',
	'4752': 'Retail sale of hardware, paint and glass in specialized stores',
	'4753': 'Retail trade of carpets, rugs and wall and floor coverings in specialized stores',
	'4754': 'Retail sale of household appliances in specialized stores',
	'4759':
		'Retail sale of furniture, lighting equipment and other household goods in specialized stores',
	'4761': 'Retail sale of books in specialized stores',
	'4762': 'Retail trade of newspapers and stationery in specialized stores',
	'4763': 'Retail trade of newspapers and stationery in specialized stores',
	'4764': 'Retail sale of sporting goods in specialized stores',
	'4765': 'Retail sale of games and toys in specialized stores',
	'4771': 'Retail sale of garments in specialized stores',
	'4772': 'Retail sale of footwear and leather goods in specialized stores',
	'4773': 'Retail sale of pharmaceutical products in specialized establishments',
	'4774': 'Retail sale of medical and orthopedic articles in specialized stores',
	'4775': 'Retail sale of cosmetic and hygiene products in specialized stores',
	'4776':
		'Retail sale of flowers, plants, seeds, fertilizers, pet food and pet food in specialized stores',
	'4777': 'Retail sale of watches and jewelry in specialized stores',
	'4778': 'Other retail sale of new goods in specialized stores',
	'4779': 'Retail trade of second-hand goods in stores',
	'4781': 'Retail sale of foodstuffs, beverages and tobacco in stalls and street markets',
	'4782': 'Retail trade of textile products, clothing and footwear in stalls and flea markets',
	'4789': 'Retail trade of other products in stalls and at flea markets',
	'4791': 'Retail trade by mail order or Internet',
	'4799': 'Other retail trade not carried out in stores, stalls or flea markets',
};
// 	'4910': 'Transporte interurbano de pasajeros por ferrocarril',
// 	'4920': 'Transporte de mercancías por ferrocarril',
// 	'4931': 'Transporte terrestre urbano y suburbano de pasajeros',
// 	'4932': 'Transporte por taxi',
// 	'4939': 'tipos de transporte terrestre de pasajeros n.c.o.p.',
// 	'4941': 'Transporte de mercancías por carretera',
// 	'4942': 'Servicios de mudanza',
// 	'4950': 'Transporte por tubería',
// 	'5010': 'Transporte marítimo de pasajeros',
// 	'5020': 'Transporte marítimo de mercancías',
// 	'5030': 'Transporte de pasajeros por vías navegables interiores',
// 	'5040': 'Transporte de mercancías por vías navegables interiores',
// 	'5110': 'Transporte aéreo de pasajeros',
// 	'5121': 'Transporte aéreo de mercancías',
// 	'5122': 'Transporte espacial',
// 	'5210': 'Depósito y almacenamiento',
// 	'5221': 'Actividades anexas al transporte terrestre',
// 	'5222': 'Actividades anexas al transporte marítimo y por vías navegables interiores',
// 	'5223': 'Actividades anexas al transporte aéreo',
// 	'5224': 'Manipulación de mercancías',
// 	'5229': 'Otras actividades anexas al transporte',
// 	'5310': 'Actividades postales sometidas a la obligación del servicio universal',
// 	'5320': 'Otras actividades postales y de correos',
// 	'5510': 'Hoteles y alojamientos similares',
// 	'5520': 'Alojamientos turísticos y otros alojamientos de corta estancia',
// 	'5530': 'Campings y aparcamientos para caravanas',
// 	'5590': 'Otros alojamientos',
// 	'5610': 'Restaurantes y puestos de comidas',
// 	'5621': 'Provisión de comidas preparadas para eventos',
// 	'5629': 'Otros servicios de comidas',
// 	'5630': 'Establecimientos de bebidas',
// 	'5811': 'Edición de libros',
// 	'5812': 'Edición de directorios y guías de direcciones postales',
// 	'5813': 'Edición de periódicos',
// 	'5814': 'Edición de revistas',
// 	'5819': 'Otras actividades editoriales',
// 	'5821': 'Edición de videojuegos',
// 	'5829': 'Edición de otros programas informáticos',
// 	'5912': 'Actividades de postproducción cinematográfica, de vídeo y de programas de televisión',
// 	'5914': 'Actividades de exhibición cinematográfica',
// 	'5915': 'Actividades de producción cinematográfica y de vídeo',
// 	'5916': 'Actividades de producciones de programas de televisión',
// 	'5917': 'Actividades de distribución cinematográfica y de vídeo',
// 	'5918': 'Actividades de distribución de programas de televisión',
// 	'5920': 'Actividades de grabación de sonido y edición musical',
// 	'6010': 'Actividades de radiodifusión',
// 	'6020': 'Actividades de programación y emisión de televisión',
// 	'6110': 'Telecomunicaciones por cable',
// 	'6120': 'Telecomunicaciones inalámbricas',
// 	'6130': 'Telecomunicaciones por satélite',
// 	'6190': 'Otras actividades de telecomunicaciones',
// 	'6201': 'Actividades de programación informática',
// 	'6202': 'Actividades de consultoría informática',
// 	'6203': 'Gestión de recursos informáticos',
// 	'6209': 'Otros servicios relacionados con las tecnologías de la información y la informática',
// 	'6311': 'Proceso de datos, hosting y actividades relacionadas',
// 	'6312': 'Portales web',
// 	'6391': 'Actividades de las agencias de noticias',
// 	'6399': 'Otros servicios de información n.c.o.p.',
// 	'6411': 'Banco central',
// 	'6419': 'Otra intermediación monetaria',
// 	'6420': 'Actividades de las sociedades holding',
// 	'6430': 'Inversión colectiva, fondos y entidades financieras similares',
// 	'6491': 'Arrendamiento financiero',
// 	'6492': 'Otras actividades crediticias',
// 	'6499': 'Otros servicios financieros, excepto seguros y fondos de pensiones n.c.o.p.',
// 	'6511': 'Seguros de vida',
// 	'6512': 'Seguros distintos de los seguros de vida',
// 	'6520': 'Reaseguros',
// 	'6530': 'Fondos de pensiones',
// 	'6611': 'Administración de mercados financieros',
// 	'6612': 'Actividades de intermediación en operaciones con valores y otros activos',
// 	'6619':
// 		'Otras actividades auxiliares a los servicios financieros, excepto seguros y fondos de pensiones',
// 	'6621': 'Evaluación de riesgos y daños',
// 	'6622': 'Actividades de agentes y corredores de seguros',
// 	'6629': 'Otras actividades auxiliares a seguros y fondos de pensiones',
// 	'6630': 'Actividades de gestión de fondos',
// 	'6910': 'Actividades jurídicas',
// 	'6920': 'Actividades de contabilidad, teneduría de libros, auditoría y asesoría fiscal',
// 	'7010': 'Actividades de las sedes centrales',
// 	'7021': 'Relaciones públicas y comunicación',
// 	'7022': 'Otras actividades de consultoría de gestión empresarial',
// 	'7111': 'Servicios técnicos de arquitectura',
// 	'7112':
// 		'Servicios técnicos de ingeniería y otras actividades relacionadas con el asesoramiento técnico',
// 	'7120': 'Ensayos y análisis técnicos',
// 	'7211': 'Investigación y desarrollo experimental en biotecnología',
// 	'7219': 'Otra investigación y desarrollo experimental en ciencias naturales y técnicas',
// 	'7220': 'Investigación y desarrollo experimental en ciencias sociales y humanidades',
// 	'7311': 'Agencias de publicidad',
// 	'7312': 'Servicios de representación de medios de comunicación',
// 	'7320': 'Estudio de mercado y realización de encuestas de opinión pública',
// 	'7410': 'Actividades de diseño especializado',
// 	'7420': 'Actividades de fotografía',
// 	'7430': 'Actividades de traducción e interpretación',
// 	'7490': 'Otras actividades profesionales, científicas y técnicas n.c.o.p.',
// 	'7500': 'Actividades veterinarias',
// 	'8411': 'Actividades generales de la Administración Pública',
// 	'8412':
// 		'Regulación de las actividades sanitarias, educativas y culturales y otros servicios sociales, excepto Seguridad Social',
// 	'8413': 'Regulación de la actividad económica y contribución a su mayor eficiencia',
// 	'8421': 'Asuntos exteriores',
// 	'8422': 'Defensa',
// 	'8423': 'Justicia',
// 	'8424': 'Orden público y seguridad',
// 	'8425': 'Protección civil',
// 	'8430': 'Seguridad Social obligatoria',
// 	'9001': 'Artes escénicas',
// 	'9002': 'Actividades auxiliares a las artes escénicas',
// 	'9003': 'Creación artística y literaria',
// 	'9004': 'Gestión de salas de espectáculos',
// 	'9102': 'Actividades de museos',
// 	'9103': 'Gestión de lugares y edificios históricos',
// 	'9104': 'Actividades de los jardines botánicos, parques zoológicos y reservas naturales',
// 	'9105': 'Actividades de bibliotecas',
// 	'9106': 'Actividades de archivos',
// 	'9200': 'Actividades de juegos de azar y apuestas',
// 	'9311': 'Gestión de instalaciones deportivas',
// 	'9312': 'Actividades de los clubes deportivos',
// 	'9313': 'Actividades de los gimnasios',
// 	'9319': 'Otras actividades deportivas',
// 	'9321': 'Actividades de los parques de atracciones y los parques temáticos',
// 	'9329': 'Otras actividades recreativas y de entretenimiento',
// 	'9411': 'Actividades de organizaciones empresariales y patronales',
// 	'9412': 'Actividades de organizaciones profesionales',
// 	'9420': 'Actividades sindicales',
// 	'9491': 'Actividades de organizaciones religiosas',
// 	'9492': 'Actividades de organizaciones políticas',
// 	'9499': 'Otras actividades asociativas n.c.o.p.',
// 	'9511': 'Reparación de ordenadores y equipos periféricos',
// 	'9512': 'Reparación de equipos de comunicación',
// 	'9521': 'Reparación de aparatos electrónicos de audio y vídeo de uso doméstico',
// 	'9522': 'Reparación de aparatos electrodomésticos y de equipos para el hogar y el jardín',
// 	'9523': 'Reparación de calzado y artículos de cuero',
// 	'9524': 'Reparación de muebles y artículos de menaje',
// 	'9525': 'Reparación de relojes y joyería',
// 	'9529': 'Reparación de otros efectos personales y artículos de uso doméstico',
// 	'9601': 'Lavado y limpieza de prendas textiles y de piel',
// 	'9602': 'Peluquería y otros tratamientos de belleza',
// 	'9603': 'Pompas fúnebres y actividades relacionadas',
// 	'9604': 'Actividades de mantenimiento físico',
// 	'9609': 'Otras servicios personales n.c.o.p.',
// 	'0510': 'Extracción de antracita y hulla',
// 	'0520': 'Extracción de lignito',
// 	'0610': 'Extracción de crudo de petróleo',
// 	'0620': 'Extracción de gas natural',
// 	'0710': 'Extracción de minerales de hierro',
// 	'0721': 'Extracción de minerales de uranio y torio',
// 	'0729': 'Extracción de otros minerales metálicos no férreos',
// 	'0811':
// 		'Extracción de piedra ornamental y para la construcción, piedra caliza, yeso, creta y pizarra',
// 	'0812': 'Extracción de gravas y arenas; extracción de arcilla y caolín',
// 	'0891': 'Extracción de minerales para productos químicos y fertilizantes',
// 	'0892': 'Extracción de turba',
// 	'0893': 'Extracción de sal',
// 	'0899': 'Otras industrias extractivas n.c.o.p.',
// 	'0910': 'Actividades de apoyo a la extracción de petróleo y gas natural',
// 	'0990': 'Actividades de apoyo a otras industrias extractivas',
// 	'1011': 'Procesado y conservación de carne',
// 	'1012': 'Procesado y conservación de volatería',
// 	'1013': 'Elaboración de productos cárnicos y de volatería',
// 	'1021': 'Procesado de pescados, crustáceos y moluscos',
// 	'1022': 'Fabricación de conservas de pescado',
// 	'1031': 'Procesado y conservación de patatas',
// 	'1032': 'Elaboración de zumos de frutas y hortalizas',
// 	'1039': 'Otro procesado y conservación de frutas y hortalizas',
// 	'1042': 'Fabricación de margarina y grasas comestibles similares',
// 	'1043': 'Fabricación de aceite de oliva',
// 	'1044': 'Fabricación de otros aceites y grasas',
// 	'1052': 'Elaboración de helados',
// 	'1053': 'Fabricación de quesos',
// 	'1054': 'Preparación de leche y otros productos lácteos',
// 	'1061': 'Fabricación de productos de molinería',
// 	'1062': 'Fabricación de almidones y productos amiláceos',
// 	'1071': 'Fabricación de pan y de productos frescos de panadería y pastelería',
// 	'1072': 'Fabricación de galletas y productos de panadería y pastelería de larga duración',
// 	'1073': 'Fabricación de pastas alimenticias, cuscús y productos similares',
// 	'1081': 'Fabricación de azúcar',
// 	'1082': 'Fabricación de cacao, chocolate y productos de confitería',
// 	'1083': 'Elaboración de café, té e infusiones',
// 	'1084': 'Elaboración de especias, salsas y condimentos',
// 	'1085': 'Elaboración de platos y comidas preparados',
// 	'1086': 'Elaboración de preparados alimenticios homogeneizados y alimentos dietéticos',
// 	'1089': 'Elaboración de otros productos alimenticios n.c.o.p.',
// 	'1091': 'Fabricación de productos para la alimentación de animales de granja',
// 	'1092': 'Fabricación de productos para la alimentación de animales de compañía',
// 	'1101': 'Destilación, rectificación y mezcla de bebidas alcohólicas',
// 	'1102': 'Elaboración de vinos',
// 	'1103': 'Elaboración de sidra y otras bebidas fermentadas a partir de frutas',
// 	'1104': 'Elaboración de otras bebidas no destiladas, procedentes de la fermentación',
// 	'1105': 'Fabricación de cerveza',
// 	'1106': 'Fabricación de malta',
// 	'1107':
// 		'Fabricación de bebidas no alcohólicas; producción de aguas minerales y otras aguas embotelladas',
// 	'1200': 'Industria del tabaco',
// 	'1310': 'Preparación e hilado de fibras textiles',
// 	'1320': 'Fabricación de tejidos textiles',
// 	'1330': 'Acabado de textiles',
// 	'1391': 'Fabricación de tejidos de punto',
// 	'1392': 'Fabricación de artículos confeccionados con textiles, excepto prendas de vestir',
// 	'1393': 'Fabricación de alfombras y moquetas',
// 	'1394': 'Fabricación de cuerdas, cordeles, bramantes y redes',
// 	'1395':
// 		'Fabricación de telas no tejidas y artículos confeccionados con ellas, excepto prendas de vestir',
// 	'1396': 'Fabricación de otros productos textiles de uso técnico e industrial',
// 	'1399': 'Fabricación de otros productos textiles n.c.o.p.',
// 	'1411': 'Confección de prendas de vestir de cuero',
// 	'1412': 'Confección de ropa de trabajo',
// 	'1413': 'Confección de otras prendas de vestir exteriores',
// 	'1414': 'Confección de ropa interior',
// 	'1419': 'Confección de otras prendas de vestir y accesorios',
// 	'1420': 'Fabricación de artículos de peletería',
// 	'1431': 'Confección de calcetería',
// 	'1439': 'Confección de otras prendas de vestir de punto',
// 	'1511': 'Preparación, curtido y acabado del cuero; preparación y teñido de pieles',
// 	'1512': 'Fabricación de artículos de marroquinería, viaje y de guarnicionería y talabartería',
// 	'1520': 'Fabricación de calzado',
// 	'1610': 'Aserrado y cepillado de la madera',
// 	'1621': 'Fabricación de chapas y tableros de madera',
// 	'1622': 'Fabricación de suelos de madera ensamblados',
// 	'1623':
// 		'Fabricación de otras estructuras de madera y piezas de carpintería y ebanistería para la construcción',
// 	'1624': 'Fabricación de envases y embalajes de madera',
// 	'1629': 'Fabricación de otros productos de madera; artículos de corcho, cestería y espartería',
// 	'1711': 'Fabricación de pasta papelera',
// 	'1712': 'Fabricación de papel y cartón',
// 	'1721':
// 		'Fabricación de papel y cartón ondulados; fabricación de envases y embalajes de papel y cartón',
// 	'1722': 'Fabricación de artículos de papel y cartón para uso doméstico, sanitario e higiénico',
// 	'1723': 'Fabricación de artículos de papelería',
// 	'1724': 'Fabricación de papeles pintados',
// 	'1729': 'Fabricación de otros artículos de papel y cartón',
// 	'1811': 'Artes gráficas y servicios relacionados con las mismas',
// 	'1812': 'Otras actividades de impresión y artes gráficas',
// 	'1813': 'Servicios de preimpresión y preparación de soportes',
// 	'1814': 'Encuadernación y servicios relacionados con la misma',
// 	'1820': 'Reproducción de soportes grabados',
// 	'1910': 'Coquerías',
// 	'1920': 'Refino de petróleo',
// 	'2011': 'Fabricación de gases industriales',
// 	'2012': 'Fabricación de colorantes y pigmentos',
// 	'2013': 'Fabricación de otros productos básicos de química inorgánica',
// 	'2014': 'Fabricación de otros productos básicos de química orgánica',
// 	'2015': 'Fabricación de fertilizantes y compuestos nitrogenados',
// 	'2016': 'Fabricación de plásticos en formas primarias',
// 	'2017': 'Fabricación de caucho sintético en formas primarias',
// 	'2020': 'Fabricación de pesticidas y otros productos agroquímicos',
// 	'2030':
// 		'Fabricación de pinturas, barnices y revestimientos similares; tintas de imprenta y masillas',
// 	'2041': 'Fabricación de jabones, detergentes y otros artículos de limpieza y abrillantamiento',
// 	'2042': 'Fabricación de perfumes y cosméticos',
// 	'2051': 'Fabricación de explosivos',
// 	'2052': 'Fabricación de colas',
// 	'2053': 'Fabricación de aceites esenciales',
// 	'2059': 'Fabricación de otros productos químicos n.c.o.p.',
// 	'2060': 'Fabricación de fibras artificiales y sintéticas',
// 	'2110': 'Fabricación de productos farmacéuticos de base',
// 	'2120': 'Fabricación de especialidades farmacéuticas',
// 	'2211':
// 		'Fabricación de neumáticos y cámaras de caucho; reconstrucción y recauchutado de neumáticos',
// 	'2219': 'Fabricación de otros productos de caucho',
// 	'2221': 'Fabricación de placas, hojas, tubos y perfiles de plástico',
// 	'2222': 'Fabricación de envases y embalajes de plástico',
// 	'2223': 'Fabricación de productos de plástico para la construcción',
// 	'2229': 'Fabricación de otros productos de plástico',
// 	'2311': 'Fabricación de vidrio plano',
// 	'2312': 'Manipulado y transformación de vidrio plano',
// 	'2313': 'Fabricación de vidrio hueco',
// 	'2314': 'Fabricación de fibra de vidrio',
// 	'2319': 'Fabricación y manipulado de otro vidrio, incluido el vidrio técnico',
// 	'2320': 'Fabricación de productos cerámicos refractarios',
// 	'2331': 'Fabricación de azulejos y baldosas de cerámica',
// 	'2332': 'Fabricación de ladrillos, tejas y productos de tierras cocidas para la construcción',
// 	'2341': 'Fabricación de artículos cerámicos de uso doméstico y ornamental',
// 	'2342': 'Fabricación de aparatos sanitarios cerámicos',
// 	'2343': 'Fabricación de aisladores y piezas aislantes de material cerámico',
// 	'2344': 'Fabricación de otros productos cerámicos de uso técnico',
// 	'2349': 'Fabricación de otros productos cerámicos',
// 	'2351': 'Fabricación de cemento',
// 	'2352': 'Fabricación de cal y yeso',
// 	'2361': 'Fabricación de elementos de hormigón para la construcción',
// 	'2362': 'Fabricación de elementos de yeso para la construcción',
// 	'2363': 'Fabricación de hormigón fresco',
// 	'2364': 'Fabricación de mortero',
// 	'2365': 'Fabricación de fibrocemento',
// 	'2369': 'Fabricación de otros productos de hormigón, yeso y cemento',
// 	'2370': 'Corte, tallado y acabado de la piedra',
// 	'2391': 'Fabricación de productos abrasivos',
// 	'2399': 'Fabricación de otros productos minerales no metálicos n.c.o.p.',
// 	'2410': 'Fabricación de productos básicos de hierro, acero y ferroaleaciones',
// 	'2420': 'Fabricación de tubos, tuberías, perfiles huecos y sus accesorios, de acero',
// 	'2431': 'Estirado en frío',
// 	'2432': 'Laminación en frío',
// 	'2433': 'Producción de perfiles en frío por conformación con plegado',
// 	'2434': 'Trefilado en frío',
// 	'2441': 'Producción de metales preciosos',
// 	'2442': 'Producción de aluminio',
// 	'2443': 'Producción de plomo, zinc y estaño',
// 	'2444': 'Producción de cobre',
// 	'2445': 'Producción de otros metales no férreos',
// 	'2446': 'Procesamiento de combustibles nucleares',
// 	'2451': 'Fundición de hierro',
// 	'2452': 'Fundición de acero',
// 	'2453': 'Fundición de metales ligeros',
// 	'2454': 'Fundición de otros metales no férreos',
// 	'2511': 'Fabricación de estructuras metálicas y sus componentes',
// 	'2512': 'Fabricación de carpintería metálica',
// 	'2521': 'Fabricación de radiadores y calderas para calefacción central',
// 	'2529': 'Fabricación de otras cisternas, grandes depósitos y contenedores de metal',
// 	'2530': 'Fabricación de generadores de vapor, excepto calderas de calefacción central',
// 	'2540': 'Fabricación de armas y municiones',
// 	'2550': 'Forja, estampación y embutición de metales; metalurgia de polvos',
// 	'2561': 'Tratamiento y revestimiento de metales',
// 	'2562': 'Ingeniería mecánica por cuenta de terceros',
// 	'2571': 'Fabricación de artículos de cuchillería y cubertería',
// 	'2572': 'Fabricación de cerraduras y herrajes',
// 	'2573': 'Fabricación de herramientas',
// 	'2591': 'Fabricación de bidones y toneles de hierro o acero',
// 	'2592': 'Fabricación de envases y embalajes metálicos ligeros',
// 	'2593': 'Fabricación de productos de alambre, cadenas y muelles',
// 	'2594': 'Fabricación de pernos y productos de tornillería',
// 	'2599': 'Fabricación de otros productos metálicos n.c.o.p.',
// 	'2611': 'Fabricación de componentes electrónicos',
// 	'2612': 'Fabricación de circuitos impresos ensamblados',
// 	'2620': 'Fabricación de ordenadores y equipos periféricos',
// 	'2630': 'Fabricación de equipos de telecomunicaciones',
// 	'2640': 'Fabricación de productos electrónicos de consumo',
// 	'2651': 'Fabricación de instrumentos y aparatos de medida, verificación y navegación',
// 	'2652': 'Fabricación de relojes',
// 	'2660': 'Fabricación de equipos de radiación, electromédicos y electroterapéuticos',
// 	'2670': 'Fabricación de instrumentos de óptica y equipo fotográfico',
// 	'2680': 'Fabricación de soportes magnéticos y ópticos',
// 	'2711': 'Fabricación de motores, generadores y transformadores eléctricos',
// 	'2712': 'Fabricación de aparatos de distribución y control eléctrico',
// 	'2720': 'Fabricación de pilas y acumuladores eléctricos',
// 	'2731': 'Fabricación de cables de fibra óptica',
// 	'2732': 'Fabricación de otros hilos y cables electrónicos y eléctricos',
// 	'2733': 'Fabricación de dispositivos de cableado',
// 	'2740': 'Fabricación de lámparas y aparatos eléctricos de iluminación',
// 	'2751': 'Fabricación de electrodomésticos',
// 	'2752': 'Fabricación de aparatos domésticos no eléctricos',
// 	'2790': 'Fabricación de otro material y equipo eléctrico',
// 	'2811':
// 		'Fabricación de motores y turbinas, excepto los destinados a aeronaves, vehículos automóviles y ciclomotores',
// 	'2812': 'Fabricación de equipos de transmisión hidráulica y neumática',
// 	'2813': 'Fabricación de otras bombas y compresores',
// 	'2814': 'Fabricación de otra grifería y válvulas',
// 	'2815': 'Fabricación de cojinetes, engranajes y órganos mecánicos de transmisión',
// 	'2821': 'Fabricación de hornos y quemadores',
// 	'2822': 'Fabricación de maquinaria de elevación y manipulación',
// 	'2823': 'Fabricación de máquinas y equipos de oficina, excepto equipos informáticos',
// 	'2824': 'Fabricación de herramientas eléctricas manuales',
// 	'2825': 'Fabricación de maquinaria de ventilación y refrigeración no doméstica',
// 	'2829': 'Fabricación de otra maquinaria de uso general n.c.o.p.',
// 	'2830': 'Fabricación de maquinaria agraria y forestal',
// 	'2841': 'Fabricación de máquinas herramienta para trabajar el metal',
// 	'2849': 'Fabricación de otras máquinas herramienta',
// 	'2891': 'Fabricación de maquinaria para la industria metalúrgica',
// 	'2892': 'Fabricación de maquinaria para las industrias extractivas y de la construcción',
// 	'2893': 'Fabricación de maquinaria para la industria de la alimentación, bebidas y tabaco',
// 	'2894': 'Fabricación de maquinaria para las industrias textil, de la confección y del cuero',
// 	'2895': 'Fabricación de maquinaria para la industria del papel y del cartón',
// 	'2896': 'Fabricación de maquinaria para la industria del plástico y el caucho',
// 	'2899': 'Fabricación de otra maquinaria para usos específicos n.c.o.p.',
// 	'2910': 'Fabricación de vehículos de motor',
// 	'2920':
// 		'Fabricación de carrocerías para vehículos de motor; fabricación de remolques y semirremolques',
// 	'2931': 'Fabricación de equipos eléctricos y electrónicos para vehículos de motor',
// 	'2932': 'Fabricación de otros componentes, piezas y accesorios para vehículos de motor',
// 	'3011': 'Construcción de barcos y estructuras flotantes',
// 	'3012': 'Construcción de embarcaciones de recreo y deporte',
// 	'3020': 'Fabricación de locomotoras y material ferroviario',
// 	'3030': 'Construcción aeronáutica y espacial y su maquinaria',
// 	'3040': 'Fabricación de vehículos militares de combate',
// 	'3091': 'Fabricación de motocicletas',
// 	'3092': 'Fabricación de bicicletas y de vehículos para personas con discapacidad',
// 	'3099': 'Fabricación de otro material de transporte n.c.o.p.',
// 	'3101': 'Fabricación de muebles de oficina y de establecimientos comerciales',
// 	'3102': 'Fabricación de muebles de cocina',
// 	'3103': 'Fabricación de colchones',
// 	'3109': 'Fabricación de otros muebles',
// 	'3211': 'Fabricación de monedas',
// 	'3212': 'Fabricación de artículos de joyería y artículos similares',
// 	'3213': 'Fabricación de artículos de bisutería y artículos similares',
// 	'3220': 'Fabricación de instrumentos musicales',
// 	'3230': 'Fabricación de artículos de deporte',
// 	'3240': 'Fabricación de juegos y juguetes',
// 	'3250': 'Fabricación de instrumentos y suministros médicos y odontológicos',
// 	'3291': 'Fabricación de escobas, brochas y cepillos',
// 	'3299': 'Otras industrias manufactureras n.c.o.p.',
// 	'3311': 'Reparación de productos metálicos',
// 	'3312': 'Reparación de maquinaria',
// 	'3313': 'Reparación de equipos electrónicos y ópticos',
// 	'3314': 'Reparación de equipos eléctricos',
// 	'3315': 'Reparación y mantenimiento naval',
// 	'3316': 'Reparación y mantenimiento aeronáutico y espacial',
// 	'3317': 'Reparación y mantenimiento de otro material de transporte',
// 	'3319': 'Reparación de otros equipos',
// 	'3320': 'Instalación de máquinas y equipos industriales',
// 	'6810': 'Compraventa de bienes inmobiliarios por cuenta propia',
// 	'6820': 'Alquiler de bienes inmobiliarios por cuenta propia',
// 	'6831': 'Agentes de la propiedad inmobiliaria',
// 	'6832': 'Gestión y administración de la propiedad inmobiliaria',
// 	'7711': 'Alquiler de automóviles y vehículos de motor ligeros',
// 	'7712': 'Alquiler de camiones',
// 	'7721': 'Alquiler de artículos de ocio y deportivos',
// 	'7722': 'Alquiler de cintas de vídeo y discos',
// 	'7729': 'Alquiler de otros efectos personales y artículos de uso doméstico',
// 	'7731': 'Alquiler de maquinaria y equipo de uso agrícola',
// 	'7732': 'Alquiler de maquinaria y equipo para la construcción e ingeniería civil',
// 	'7733': 'Alquiler de maquinaria y equipo de oficina, incluidos ordenadores',
// 	'7734': 'Alquiler de medios de navegación',
// 	'7735': 'Alquiler de medios de transporte aéreo',
// 	'7739': 'Alquiler de otra maquinaria, equipos y bienes tangibles n.c.o.p.',
// 	'7740':
// 		'Arrendamiento de la propiedad intelectual y productos similares, excepto trabajos protegidos por los derechos de autor',
// 	'7810': 'Actividades de las agencias de colocación',
// 	'7820': 'Actividades de las empresas de trabajo temporal',
// 	'7830': 'Otra provisión de recursos humanos',
// 	'7911': 'Actividades de las agencias de viajes',
// 	'7912': 'Actividades de los operadores turísticos',
// 	'7990': 'Otros servicios de reservas y actividades relacionadas con los mismos',
// 	'8010': 'Actividades de seguridad privada',
// 	'8020': 'Servicios de sistemas de seguridad',
// 	'8030': 'Actividades de investigación',
// 	'8110': 'Servicios integrales a edificios e instalaciones',
// 	'8121': 'Limpieza general de edificios',
// 	'8122': 'Otras actividades de limpieza industrial y de edificios',
// 	'8129': 'Otras actividades de limpieza',
// 	'8130': 'Actividades de jardinería',
// 	'8211': 'Servicios administrativos combinados',
// 	'8219':
// 		'Actividades de fotocopiado, preparación de documentos y otras actividades especializadas de oficina',
// 	'8220': 'Actividades de los centros de llamadas',
// 	'8230': 'Organización de convenciones y ferias de muestras',
// 	'8291': 'Actividades de las agencias de cobros y de información comercial',
// 	'8292': 'Actividades de envasado y empaquetado',
// 	'8299': 'Otras actividades de apoyo a las empresas n.c.o.p.',
// 	'8510': 'Educación preprimaria',
// 	'8520': 'Educación primaria',
// 	'8531': 'Educación secundaria general',
// 	'8532': 'Educación secundaria técnica y profesional',
// 	'8541': 'Educación postsecundaria no terciaria',
// 	'8543': 'Educación universitaria',
// 	'8544': 'Educación terciaria no universitaria',
// 	'8551': 'Educación deportiva y recreativa',
// 	'8552': 'Educación cultural',
// 	'8553': 'Actividades de las escuelas de conducción y pilotaje',
// 	'8559': 'Otra educación n.c.o.p.',
// 	'8560': 'Actividades auxiliares a la educación',
// 	'8610': 'Actividades hospitalarias',
// 	'8621': 'Actividades de medicina general',
// 	'8622': 'Actividades de medicina especializada',
// 	'8623': 'Actividades odontológicas',
// 	'8690': 'Otras actividades sanitarias',
// 	'8710': 'Asistencia en establecimientos residenciales con cuidados sanitarios',
// 	'8720':
// 		'Asistencia en establecimientos residenciales para personas con discapacidad intelectual, enfermedad mental y drogodependencia',
// 	'8731': 'Asistencia en establecimientos residenciales para personas mayores',
// 	'8732': 'Asistencia en establecimientos residenciales para personas con discapacidad física',
// 	'8790': 'Otras actividades de asistencia en establecimientos residenciales',
// 	'8811': 'Actividades de servicios sociales sin alojamiento para personas mayores',
// 	'8812': 'Actividades de servicios sociales sin alojamiento para personas con discapacidad',
// 	'8891': 'Actividades de cuidado diurno de niños',
// 	'8899': 'Otros actividades de servicios sociales sin alojamiento n.c.o.p.',
// 	'9700': 'Actividades de los hogares como empleadores de personal doméstico',
// 	'9810': 'Actividades de los hogares como productores de bienes para uso propio',
// 	'9820': 'Actividades de los hogares como productores de servicios para uso propio',
// 	'9900': 'Actividades de organizaciones y organismos extraterritoriales',
// };
